import { Center, CenterProps, Spinner, Text } from "@chakra-ui/react";
import React from "react";

// type Props = object;
interface Props extends CenterProps {
  loadingText?: string;
}

const Loading = ({
  h = "calc(100vh - 60px)",
  // loadingText = "Loading Please wait...",
  loadingText = "",
}: Props) => {
  return (
    <Center h={h} flexDir={"column"} gap={"4"} bg="transparent">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary"
        size="xl"
      />
      <Text>{loadingText}</Text>
    </Center>
  );
};

export default Loading;
