import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { PATH_NAMES } from "../routes/path-names";
import { useEffect, useState } from "react";
import { useGetMyProgramByTitleFromUrl } from ".";
import { MyProgram } from "../types";
import { unSlutText } from "../utils";

export const useGetProgramByTitle = (title: string) => {
  const { payload } = useAppSelector((state) => state.trainingPrograms);
  const program = payload?.programs?.all.find(
    (program) => program.title.toLowerCase() === title.split("-").join(" ")
  );
  return program;
};

export const useGetMyProgramByTitle = (title: string) => {
  const { payload, loading } = useAppSelector(
    (state) => state.trainingPrograms
  );
  const [program, setProgram] = useState<MyProgram | undefined>(undefined);

  useEffect(() => {
    if (payload?.enrolledPrograms) {
      payload.enrolledPrograms.completed.map((comPro) => {
        if (comPro.program.title.toLowerCase() === unSlutText(title)) {
          setProgram(comPro);
        }
      });
      payload.enrolledPrograms.current.map((currPro) => {
        if (currPro.program.title.toLowerCase() === unSlutText(title)) {
          setProgram(currPro);
        }
      });
    }
  }, [payload?.enrolledPrograms]);

  return { program, loading };
};

export const useGroupByCategory = (programsArray?: TrainingProgram[]) => {
  const allPrograms = useAppSelector(
    (state) => state.trainingPrograms.payload?.programs?.all
  );
  const programs = programsArray ?? allPrograms;

  const groupedPrograms = programs?.reduce((acc, curr) => {
    const category = curr.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(curr);
    return acc;
  }, {} as { [key: string]: TrainingProgram[] });
  return groupedPrograms;
};

export const useFilterPrograms = (searchTerm?: string) => {
  const allPrograms = useAppSelector(
    (state) => state.trainingPrograms.payload?.programs?.all
  );
  const text = searchTerm ?? "";

  const filterPrograms = allPrograms
    ?.filter((program) =>
      program.title.toLowerCase().includes(text.toLowerCase())
    )
    .filter((program) =>
      program.category.toLowerCase().includes(text.toLowerCase())
    );

  const groupedPrograms = useGroupByCategory(filterPrograms);
  return groupedPrograms;
};

export const useGoToLesson = () => {
  const { programTitle, lessonId } = useParams();
  const navigate = useNavigate();
  const next = (lessonId: string) =>
    navigate(`${PATH_NAMES.DASHBOARD}/my-learning/${programTitle}/${lessonId}`);
  return { next, currentLesson: lessonId };
};

export const useGoToNextLesson = () => {
  const { currentLesson, next } = useGoToLesson();
  const [nextLesson, setNextLesson] = useState<Lesson>();
  const { lessons, loading } = useGetAllLessons();
  const [nextDisable, setNextDisable] = useState(false);

  useEffect(() => {
    if (loading || !lessons || !currentLesson) {
      setNextLesson(undefined);
      setNextDisable(true);
      return;
    }

    const lessonIndex = lessons.findIndex((elt) => elt._id === currentLesson);

    if (lessonIndex !== -1 && lessonIndex + 1 < lessons.length) {
      setNextLesson(lessons[lessonIndex + 1]);
      setNextDisable(false);
    } else {
      setNextLesson(undefined);
      setNextDisable(true);
    }
  }, [currentLesson, lessons]);

  return {
    nextLesson: () => next(nextLesson?._id ?? ""),
    nextDisable,
  };
};

export const useGetAllLessons = (programTitle?: string) => {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const { program: p1, loading: l1 } = useGetMyProgramByTitleFromUrl();
  const { program: p2, loading: l2 } = useGetMyProgramByTitle(programTitle ?? "");
  const program = programTitle ? p2?.program : p1?.program;

  useEffect(() => {
    if (program && program.courses) {
      // Flatten the lessons array from all courses and modules
      const allLessons = program.courses.flatMap((course) =>
        course.modules.flatMap((module) => module.lessons)
      );
      setLessons(allLessons);
    }
  }, [p1, p2]);

  return { lessons, loading: l1 && l2 };
};

export const useGetLesson = () => {
  const { lessonId, programTitle } = useParams<{
    lessonId: string;
    programTitle: string;
  }>();
  const navigate = useNavigate();

  const { lessons, loading } = useGetAllLessons();
  const [lesson, setLesson] = useState<Lesson>();

  useEffect(() => {
    if (!lessonId) {
      setLesson(lessons[0]);
    } else {
      const lesson = lessons.find((elt) => elt._id === lessonId);
      navigate(`${PATH_NAMES.MY_LEARNING}/${programTitle}/${lessonId}`);
      setLesson(lesson);
    }
  }, [lessons, lessonId]);

  return { lesson, loading };
};

export const useGetPercentageCompletion = (programTitle?: string) => {
  const { program: p, loading: isLoading } = useGetMyProgramByTitle(
    programTitle ?? ""
  );
  const [percentageCompletion, setPercentageCompletion] = useState("");
  const { lessons, loading } = useGetAllLessons(programTitle ?? "");
  const [completedLessons, setCompletedLessons] = useState(0);
  const { program: p2, loading: progLoading } = useGetMyProgramByTitleFromUrl();

  useEffect(() => {
    const program = programTitle ? p : p2;
    if (!program || progLoading || isLoading) return;
    console.log(program.completedLessons.length);
    setCompletedLessons(program.completedLessons.length);
  }, [p, p2]);

  useEffect(() => {
    if (loading || !lessons.length || !completedLessons) return;
    // Calculate the percentage completion based on completed lessons and total lessons
    setPercentageCompletion(
      ((completedLessons / lessons.length) * 100).toFixed(1)
    );
  }, [lessons.length, completedLessons, p, p2]);

  return { percentageCompletion, loading };
};
