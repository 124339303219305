export const PATH_NAMES = {
  ROOT: "/",
  PROGRAMS: "/programs",
  CATEGORIES: "/categories",
  // PROGRAMS_CATEGORIES: "/categories/:programCategory",
  CATEGORIES_DETAILS: "/categories/:categoryTitle",
  PROGRAM_DETAILS: "/programs/:categoryTitle/:programTitle/:programId",
  BLOG: "/blog/",
  CONTACT_US: "/contact-us",
  SIGN_UP: "/signup",
  SIGN_IN: "/signin",
  VERIFY_OTP: "/auth/verify-otp",
  ONBOARDING: "/onboarding",
  MY_PROGRAM: "my-learning/:programTitle",
  SETTINGS: "/settings",
  SETTINGS_PAGES: "/settings/:id",
  PERSONAL_INFO: "personal-info",
  CERTIFICATES: "certificates",
  DELETE_ACCOUNT: "delete-account",
  SUBSCRIPTION: "subscriptions",
  AUTH: "/auth",
  AUTH_CONTENT: ":id",
  EMAIL: "/auth/email",
  CONFIRM_OTP: "/auth/confirm-otp",
  FORGET_PASSWORD: "/auth/forget-password",
  RESET_PASSWORD: "/auth/reset-password",
  SET_PASSWORD: "/auth/set-password",
  BIO_INFO: "/auth/complete-profile",
  // PROGRAM_DETAILS: ":programTitle",
  DASHBOARD: "/dashboard",
  DASHBOARD_TAB: "/dashboard/:tab",
  MY_LEARNING: "/dashboard/my-learning",
  // LEARNING: "/dashboard/my-learning/:programTitle/:courseTitle/:moduleTitle/:lessonTitle",
  LEARNING: "/dashboard/my-learning/:programTitle",
  LESSON: "/dashboard/my-learning/:programTitle/:lessonId",
  SUPPORT: "/support",
  PRIVACY: "/privacy",
  TERMS_OF_SERVICE: "/terms-of-service",
  ABOUT: "/about-us",
  WHYUS: "/why-us",
  NOT_FOUND: "*",
};
