import { lazy } from "react";
import WhyUs from "../pages/WhyUs/WhyUs";

const lazyPages = {
  Landing: lazy(() => import("../pages/landing/Home")),
  Blog: lazy(() => import("../pages/Blog/Blog")),
  Programs: lazy(() => import("../pages/programs/Programs")),
  Settings: lazy(() => import("../pages/Settings/PersonalInfo")),
  AuthContent: lazy(() => import("../pages/auth/AuthContent")),
  ProgramsLayout: lazy(() => import("../Layout/ProgramsLayout")),
  AuthLayout: lazy(() => import("../Layout/AuthLayout")),
  SettingsLayout: lazy(() => import("../Layout/SettingsLayout")),
  SettingsContent: lazy(() => import("../pages/Settings/SettingsContent")),
  // CoursesLayout: lazy(() => import("../Layout/ProgramsLayout")),
  CategoriesLayout: lazy(() => import("../Layout/CategoriesLayout")),
  ProgramDetails: lazy(() => import("../components/Program/ProgramDetail")),
  Learning: lazy(() => import("../pages/Learning/Learning")),
  MyProgram: lazy(() => import("../components/Program/MyProgram")),
  MyLearning: lazy(() => import("../pages/programs/MyLearning")),
  Notfound: lazy(() => import("../pages/Notfound/Notfound")),
  PersonalInfo: lazy(() => import("../pages/Settings/PersonalInfo")),
  Certificates: lazy(() => import("../pages/Settings/Certificates")),
  Subscription: lazy(() => import("../pages/Settings/Subscription")),
  DeleteAccount: lazy(() => import("../pages/Settings/DeleteAccount")),
  // Categories: lazy(() => import("../pages/programs/Categories/Categories")),
  Categories: lazy(() => import("../pages/Category/Categories")),
  Dashboard: lazy(() => import("../pages/Dashboard/Dashboard")),
  DashboardLayout: lazy(() => import("../Layout/DashboardLayout")),

  CategoryDetail: lazy(() => import("../pages/Category/CategoryDetail")),
  // Program_Categories: lazy(
  //   () => import("../pages/programs/Categories/Categories")
  // ),
  PrivacyPage: lazy(() => import("../pages/privacy/Privacy.page")),
  ProtectRoute: lazy(() => import("../components/Auth/Protect")),
  TermsOfService: lazy(() => import("../pages/terms/TermsOfService")),
  AboutPage: lazy(() => import("../pages/about/About")),
  WhyUsPage: lazy(() => import("../pages/WhyUs/WhyUs")),
  RedirectIfAuthenticated: lazy(() => import("../components/Auth/Protect")),
};

export const pages = {
  Landing: lazyPages.Landing,
  Blog: lazyPages.Blog,
  Programs: lazyPages.Programs,
  Settings: lazyPages.Settings,
  AuthContent: lazyPages.AuthContent,
  ProgramsLayout: lazyPages.ProgramsLayout,
  AuthLayout: lazyPages.AuthLayout,
  SettingsLayout: lazyPages.SettingsLayout,
  SettingsContent: lazyPages.SettingsContent,
  ProgramDetails: lazyPages.ProgramDetails,
  Learning: lazyPages.Learning,
  MyLearning: lazyPages.MyLearning,
  MyProgram: lazyPages.MyProgram,
  CategoriesLayout: lazyPages.CategoriesLayout,
  Categories: lazyPages.Categories,
  CategoryDetail: lazyPages.CategoryDetail,
  ProtectRoute: lazyPages.ProtectRoute,
  RedirectIfAuthenticated: lazyPages.RedirectIfAuthenticated,
  Notfound: lazyPages.Notfound,
  Certificates: lazyPages.Certificates,
  PersonalInfo: lazyPages.PersonalInfo,
  Subscription: lazyPages.Subscription,
  DeleteAccount: lazyPages.DeleteAccount,
  DashboardLayout: lazyPages.DashboardLayout,
  Dashboard: lazyPages.Dashboard,
  PRIVACY: lazyPages.PrivacyPage,
  TermsOfService: lazyPages.TermsOfService,
  AboutPage: lazyPages.AboutPage,
  WhyUsPage: lazyPages.WhyUsPage,
};

