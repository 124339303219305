import { MyProgram, PrincipalResponse } from "../types";
import { backend, endpoints } from "./config.api";

export const getPrograms = () =>
  backend.get<PrincipalResponse>(endpoints.trainingPrograms.root);
export const getMyPrograms = () => {
  return backend.get<{
    completed: MyProgram[];
    current: MyProgram[];
    wishlist: TrainingProgram[];
  }>(endpoints.students.getPrograms);
};

export const getProgram = (id: string) =>
  backend.get<TrainingProgram>(endpoints.trainingPrograms.get(id));

export const filterPrograms = (queryString: string) =>
  backend.get<{
    programs: TrainingProgram[];
    total: number;
  }>(endpoints.trainingPrograms.filter(queryString));

export const checkEnroll = (id: string) =>
  backend.get<boolean>(endpoints.students.checkEnroll(id));

export const checkIfReview = (id: string) =>
  backend.get<boolean>(endpoints.students.checkIfReview(id));

export const postProgramReview = (
  id: string,
  data: {
    review: string;
    rating: number;
  }
) =>
  backend.post(endpoints.trainingPrograms.postReview(id), {
    data,
  });

export const leaveAComment = (
  id: string,
  data: {
    comment: string;
    rating: number;
    programId: string;
    courseId: string;
    moduleId: string;
    lessonId: string;
  }
) => backend.post(endpoints.trainingPrograms.postLessonComment(id), { data });

export const saveProgress = (
  id: string,
  data: {
    courseId: string;
    moduleId: string;
    lessonId: string;
  }
) => backend.patch(endpoints.students.saveProgress(id), data);
