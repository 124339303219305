import { RouteObject } from "react-router-dom";
import { pages as Page } from "./definition";
import { PATH_NAMES } from "./path-names";
import { LearningContextProvider } from "../pages/Learning/Learning.Context";

export const routes: Array<RouteObject> = [
  {
    path: PATH_NAMES.ROOT,
    element: <Page.Landing />,
  },
  {
    path: PATH_NAMES.BLOG,
    element: <Page.Blog />,
  },
  {
    path: PATH_NAMES.PROGRAMS,
    element: <Page.ProgramsLayout />,
    children: [
      {
        path: "",
        element: <Page.Programs />,
      },
      {
        path: PATH_NAMES.PROGRAM_DETAILS,
        element: <Page.ProgramDetails />,
      },
    ],
  },
  {
    path: PATH_NAMES.CATEGORIES,
    element: <Page.CategoriesLayout />,
    children: [
      {
        path: "",
        element: <Page.Categories />,
      },
      {
        path: PATH_NAMES.CATEGORIES_DETAILS,
        element: <Page.CategoryDetail />,
      },
    ],
  },
  {
    element: <Page.ProtectRoute />,
    children: [
      // {
      //   path: PATH_NAMES.SETTINGS,
      //   element: <Page.SettingsLayout />,
      //   children: [
      //     {
      //       path: "",
      //       element: <Page.PersonalInfo />,
      //     },
      //     {
      //       path: PATH_NAMES.SETTINGS_PAGES,
      //       element: <Page.SettingsContent />,
      //     },
      //     // {
      //     //   path: PATH_NAMES.PERSONAL_INFO,
      //     //   element: <Page.PersonalInfo />,
      //     // },
      //     // {
      //     //   path: PATH_NAMES.CERTIFICATES,
      //     //   element: <Page.Certificates />,
      //     // },
      //     // {
      //     //   path: PATH_NAMES.DELETE_ACCOUNT,
      //     //   element: <Page.DeleteAccount />,
      //     // },
      //     // {
      //     //   path: PATH_NAMES.SUBSCRIPTION,
      //     //   element: <Page.Subscription />,
      //     // },
      //   ],
      // },
      {
        element: (
          <LearningContextProvider>
            <Page.ProgramsLayout />
          </LearningContextProvider>
        ),
        children: [
          // {
          //   element: <Page.MyLearning />,
          //   path: PATH_NAMES.MY_LEARNING,
          // },
          {
            path: PATH_NAMES.MY_PROGRAM,
            element: <Page.MyProgram />,
          },
          {
            path: PATH_NAMES.LEARNING,
            element: <Page.Learning />,
          },
          {
            path: PATH_NAMES.LESSON,
            element: <Page.Learning />,
          },
        ],
      },
      {
        element: <Page.DashboardLayout />,
        path: PATH_NAMES.DASHBOARD,
      },
      {
        element: <Page.Learning />,
        path: PATH_NAMES.LEARNING,
      },
    ],
  },

  {
    path: PATH_NAMES.AUTH,
    element: <Page.AuthLayout />,
    children: [
      {
        path: "",
        element: <Page.AuthContent />,
      },
      {
        path: PATH_NAMES.AUTH_CONTENT,
        element: <Page.AuthContent />,
      },
    ],
  },
  //   ],
  // },
  {
    path: PATH_NAMES.PRIVACY,
    element: <Page.PRIVACY />,
  },
  {
    path: PATH_NAMES.TERMS_OF_SERVICE,
    element: <Page.TermsOfService />,
  },
  {
    path: PATH_NAMES.ABOUT,
    element: <Page.AboutPage />,
  },
  {
    path: PATH_NAMES.WHYUS,
    element: <Page.WhyUsPage />,
  },
  {
    path: PATH_NAMES.NOT_FOUND,
    element: <Page.Notfound />,
  },
];
