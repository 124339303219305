import { createContext, ReactNode, useMemo, useState } from "react";

// Define the shape of the context's value
interface LearningContextType {
  isPlaying: boolean;
  onPlay: () => void;
  onPause: () => void;
  handleDuration: (duration: number) => void;
  currentLesson: Lesson | null;
  videoDuration: number | null;
}

// Create the context with a default value (will be overridden by the provider)
export const LearningContext = createContext<LearningContextType>({
  isPlaying: false,
  onPause: () => {
    return;
  },
  onPlay: () => {
    return;
  },
  currentLesson: null,
  videoDuration: null,
  handleDuration: (duration: number) => {
    return;
  },
});

// Create a provider component
export const LearningContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const onPlay = () => setIsPlaying(true);
  const onPause = () => setIsPlaying(false);
  const handleDuration = (duration: number) => setVideoDuration(duration);

  const value = useMemo(
    () => ({
      onPlay,
      onPause,
      isPlaying,
      handleDuration,
      videoDuration,
      currentLesson: null, // replace with actual current lesson state
    }),
    [isPlaying]
  );

  return (
    <LearningContext.Provider value={value}>
      {children}
    </LearningContext.Provider>
  );
};
